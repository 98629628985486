<template>
  <div style="border-radius: 5px">
    <div></div>
    <div class="detal">
      <en-table-layout toolbar pagination :tableData="tableData.data" :loading="loading" :redundancy-height="-100"
        ref="multipleTable" @selection-change="handleSelectionChange" @select-all="handleSelectionAll"
        @select="handleSelection">
        <div slot="toolbar" class="inner-toolbar">
          <div class="toolbar-btns"></div>
          <div class="toolbar-search">
            <div class="stepbox">
              <h3 style="margin-right: 30px;">发票索取</h3>
              <div>
                <p>1.开票订单选择</p>
                <div class="threeth"></div>
              </div>
              <div :class="{ activediv: !activediv }">
                <div class="threeths"></div>
                <p>2.确定发票信息和地址</p>
                <div class="threeth"></div>
              </div>
            </div>
            <div class="seletbox">
              <p>温馨提示：</p>
              <p>1. 发票订单开具（单个订单不可拆分开票）。</p>
              <p>
                2.
                单张发票金额限额100万，索取金额超过100万将拆分成多张发票开具。
              </p>
              <!-- <div style="display:block;" class="hover">
                    <span>开票信息管理</span>
                    <span>查看开票记录</span>
              </div>-->
            </div>
            <div class="databox">
              <p v-if="!activediv" style="position: relative">
                可开票列表&nbsp;&nbsp;&nbsp;&nbsp;
                <el-button style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-left: 20px;
                  " @click="showPartFn(1)" type="primary" size="mini">开票信息管理</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
                <el-button style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 230px;
                  " @click="showPartFn(2)" type="primary" size="mini">查看开票记录</el-button>
              </p>
              <p v-else style="position: relative">
                开票明细&nbsp;&nbsp;&nbsp;&nbsp;
                <el-button style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                  " @click="
                    $router.push({
                      path: '/finance/invoice/Invoicemangerdetail',
                    })
                    " type="primary" size="mini">修改发票信息</el-button>
              </p>
              <div>
                <template v-if="!activediv">
                  <div>
                    <p>已选开票金额（元）</p>
                    <p class="yikai" v-if="allPage">{{ allPagePrice }}</p>
                    <p class="yikai" v-else>{{ choosePrice1 }}</p>
                  </div>
                  <div>
                    <p>待开票总金额（元）</p>
                    <p class="daikai">{{ toDoPrice0 }}</p>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p>发票抬头：{{ receiptDetail.receipt_title }}</p>
                    <p>
                      发票类型：{{
                        receiptDetail.receipt_type == "ELECTRO"
                        ? "电子普通发票"
                        : receiptDetail.receipt_type == "VATORDINARY"
                          ? "增值税普通发票"
                          : "增值税专用发票"
                      }}
                    </p>
                    <p>
                      发票金额：
                      <span v-if="allPage">{{ toDoPrice0 }}</span>
                      <span v-else>{{ choosePrice1 }}</span>
                    </p>
                    <!-- <p>发票内容：{{receiptDetail.receipt_content}}</p> -->
                    <p>发票税号：{{ receiptDetail.tax_no }}</p>
                  </div>
                  <div>
                    <!-- <p>申请时间：{{Date.now()/1000|unixToDate}}</p> -->
                    <!-- <p>供应商（发票开具方）：{{todo}}</p> -->

                    <p>开户银行：{{ receiptDetail.bank_name }}</p>
                    <p>银行账户：{{ receiptDetail.bank_account }}</p>
                    <p>注册地址：{{ receiptDetail.reg_addr }}</p>
                    <p>注册电话：{{ receiptDetail.reg_tel }}</p>
                  </div>
                </template>
                <div class="goright" v-if="!activediv">
                  <el-button @click="getReceiptStepOne" size="small" type="primary">索取发票</el-button>
                  <br />
                  <el-tooltip class="item" effect="dark" content="1.代开票金额小于¥200  2.已选开票金额小于¥200" placement="bottom">
                    <el-button class="noueb" size="small">无法开票原因？</el-button>
                  </el-tooltip>
                </div>
                <div class="goright" v-else>
                  <el-button @click="getReceiptStepTwo" size="small" type="primary">确认并提交</el-button>
                </div>
              </div>
            </div>
            <!-- <h3>开票企业信息</h3>
                                    <span class="span">申请发票金额：</span>￥22300.00 <br>
                                    <span class="span">申请发票类型：</span>增值税专用发票 <br>
                                    <span class="span">申请企业名称：</span>企业端公司名称 <br>
                                    <span class="span">开户银行：</span>中国建设银行北京回龙观支行 <br>
                                    <span class="span">开户账号：</span>201674619439764+659 <br>
                                    <span class="span">收货人：</span>XXX <br>
                                    <span class="span">申请时间：</span>2019.12.12 14:25:30 <br>
            <span class="span">联系电话：</span>1860000001 <br>-->
            <h3>开票订单信息</h3>
            <el-tabs type="card" v-model="order_type" @tab-click="handleClick">
              <el-tab-pane label="京东商品订单" name="1"></el-tab-pane>
              <el-tab-pane label="平台商品订单" name="2"></el-tab-pane>
            </el-tabs>
            <div v-if="!activediv" style="display: flex">
              <!--              <el-checkbox v-model="thisPage" @change="isChangeThisPage"-->
              <!--                >全选本页</el-checkbox-->
              <!--              >-->
              <el-checkbox v-model="allPage" @change="isChangeAllPage" style="margin-right: 10px;">全选所有页</el-checkbox>
              <div style="display: flex">
                <div class="conditions">
                  <span>订单完成时间：</span>
                  <el-date-picker style="width: 355px" v-model="time" type="daterange" value-format="timestamp"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    @change="handleTimeChange"></el-date-picker>
                </div>
                <div class="conditions" v-if="order_type === '1'" style="margin-left: 30px">
                  <span>可开票类型：</span>
                  <el-select v-model="params.invoice_type">
                    <el-option value>全部</el-option>
                    <el-option value="2" label="增值税专用发票">增值税专用发票</el-option>
                    <el-option value="3" label="电子票">电子发票</el-option>
                  </el-select>
                  <el-button @click="handleClickSousuo" type="primary" size="small"
                    style="margin-left: 30px;">搜索</el-button>
                </div>
                <div class="conditions" v-if="order_type === '2'" style="margin-left: 30px">
                  <span>供应商名称：</span>
                  <el-select filterable placeholder="请选择供应商名称" v-model="params.sellerId" clearable>
                    <el-option v-for="item in sellerList" :key="item.shop_id" :label="item.shop_name"
                      :value="item.shop_id"></el-option>
                  </el-select>
                  <el-button @click="handleClickSousuo" type="primary" size="small"
                    style="margin-left: 30px;">搜索</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="table-columns">
          <el-table-column type="selection" width="40" v-if="!activediv" fixed="left"></el-table-column>
          <el-table-column label="关联单号" prop="trade_sn" width="140"></el-table-column>
          <el-table-column label="子订单编号" prop="sn" width="140"></el-table-column>
          <el-table-column label="订单完成时间" props="complete_time" width="150"
            :formatter="formatCreatTime"></el-table-column>
          <el-table-column label="供应商（发票开具方）" width="200" prop="seller_name"></el-table-column>
          <el-table-column label="可开票类型" prop="invoice_type" v-show="order_type === '1'" width="150">
            <template slot-scope="scope">{{ scope.row.invoice_type === 2 ? '增值税专用发票' : '电子发票' }}</template>
          </el-table-column>
          <el-table-column label="商品名称 x 购买数量 / 商品单价" width="700px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.items_json" :key="index">{{ item.name }} x {{ item.num }} 单价: {{
                item.supplier_purchase_price }}</div>
            </template>
          </el-table-column>
          <el-table-column label="开票金额" prop="order_price" width="100" fixed="right">
            <template slot-scope="scope">¥{{ scope.row.supplier_order_price - scope.row.supplier_refund_price
            }}</template>
          </el-table-column>
        </template>
        <el-pagination background v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
          @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes"
          :page-size="tableData.page_size" :layout="MixinTableLayout" :total="tableData.data_total"></el-pagination>
      </en-table-layout>
    </div>
  </div>
</template>

<script>
import * as API_Invoice from '@/api/invoice'
import * as API_supplier from '@/api/supplier'
import EnTableLayout from '../../../../ui-components/TableLayout/src/main'

export default {
  name: 'goodsAudit',
  components: { EnTableLayout },
  data () {
    return {
      params: {
        page_no: 1,
        page_size: 20,
        startTime: '',
        endTime: '',
        step: 1,
        jd_seller: 1,
        invoice_check: 1,
        invoice_type: '',
        platform_invoice: 0
      },
      tableData: {},
      toDoPrice0: '', //待开票总金额 0
      hadDonePrice2: '', //已经开票了的总金额 2
      allPagePrice: '', // 全选所有页的开票金额
      sellerList: [],
      loading: false,
      activediv: '',
      value1: '',
      dddd: '',
      thisPage: false,
      choosePrice1: 0, //已选开票金额 1
      allPage: false,
      time: '',
      idsList: [],
      receiptDetail: '',
      // 可开发票订单类型 1 京东订单 2 平台订单
      order_type: '1'
    }
  },
  created () {
    // 点击搜索
    this.GET_InvoiceOrderList()
    this.GET_ToDoPrice0()
    // this.GET_HadDonePrice2();
    this.GET_ChoosePrice1()
    this.GET_SellerList()
    this.GET_ReceiptDetail()
    console.log(typeof this.order_type)
  },
  activated () { },
  methods: {
    // tabs 切换
    handleClick (tab) {
      this.order_type = tab.name
      if (this.order_type === '1') {
        this.params.jd_seller = 1
      } else {
        this.params.jd_seller = 0
      }
      this.GET_ToDoPrice0()
      this.GET_InvoiceOrderList()
    },
    handleClickSousuo () {
      // this.GET_ToDoPrice0();
      this.GET_InvoiceOrderList()
    },
    showPartFn (val) {
      if (val === 1) {
        this.$router.push({ path: '/finance/invoice/Invoicemangerdetail' })
      } else {
        this.$router.push({
          path: '/finance/invoice/Invoicemangerjilu',
          query: {
            is: '1' //供应商索取 发票  就是订单发票
          }
        })
      }
    },
    formatCreatTime (row, column, cellValue) {
      return this.$options.filters.unixToDate(row.complete_time)
    },
    GET_SellerList () {
      API_supplier.getShopList({
        shop_type: 2,
        page_size: 99999,
        page_no: 1
      }).then(res => {
        this.sellerList = res.data
      })
    },
    GET_InvoiceOrderList () {
      this.allPage = false

      if (this.params.jd_seller === 0) {
        delete this.params.invoice_type
      }
      API_Invoice.getInvoiceOrderList(this.params).then(res => {
        this.tableData = res
        let dataList = this.tableData.data
        dataList.map((item, index) => {
          dataList[index].items_json = JSON.parse(dataList[index].items_json)
        })
        this.isChangeAllPage()
      })
    },
    GET_ToDoPrice0 () {
      API_Invoice.getWaitOrderTotalPrice({
        platform_invoice: 0,
        startTime: this.params.startTime,
        endTime: this.params.endTime,
        invoice_check: 1,
        jd_seller: this.params.jd_seller
      }).then(res => {
        this.toDoPrice0 = res
      })
    },
    GET_HadDonePrice2 () {
      API_Invoice.getWaitOrderTotalPrice({
        platform_invoice: 2,
        startTime: this.params.startTime,
        endTime: this.params.endTime
      }).then(res => {
        this.hadDonePrice2 = res
      })
    },
    GET_ChoosePrice1 () {
      // 全选所有页
      if (this.allPage) {
        API_Invoice.getWaitOrderTotalPrice({
          ...this.params,
          is_all: 1
        }).then(res => {
          this.allPagePrice = res
          this.allPage = true
        })
      } else {
        if (this.idsList.length > 0) {
          API_Invoice.getWaitOrderTotalPrice({
            platform_invoice: 1,
            startTime: this.params.startTime,
            endTime: this.params.endTime,
            orderIds: this.idsList
          }).then(res => {
            this.choosePrice1 = res
          })
        } else {
          this.choosePrice1 = 0
        }
      }
    },
    handleTimeChange () {
      if (this.time && this.time.length && this.time.length > 0) {
        this.params.startTime = parseInt(this.time[0] / 1000)
        this.params.endTime = parseInt(this.time[1] / 1000 + 86399)
      } else {
        delete this.params.startTime
        delete this.params.endTime
      }
    },
    GET_ReceiptDetail () {
      API_Invoice.getDetail().then(res => {
        this.receiptDetail = res
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size
      this.GET_InvoiceOrderList()
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page
      this.GET_InvoiceOrderList()
    },
    isChangeThisPage () {
      this.allPage = false
      if (this.thisPage) {
        this.$refs.multipleTable.$refs.table.toggleAllSelection()
      } else {
        this.$refs.multipleTable.$refs.table.clearSelection()
      }
    },
    isChangeAllPage () {
      this.thisPage = false
      if (this.allPage) {
        this.$refs.multipleTable.$refs.table.toggleAllSelection()
      } else {
        this.$refs.multipleTable.$refs.table.clearSelection()
      }
    },
    handleSelectionChange (val) {
      if (!this.allPage) {
        this.thisPage = val.length === this.tableData.data.length
        let ids = []
        val.forEach(item => {
          ids.push(item.order_id)
        })
        this.idsList = ids
        // this.GET_ToDoPrice0();
        // this.GET_HadDonePrice2();
      }
      this.GET_ChoosePrice1()
    },
    handleSelectionAll () {
      this.allPage = false
    },
    handleSelection () {
      this.allPage = false
    },
    getReceiptStepOne () {
      let data = null,
        price = 0
      data = { step: 1 }
      data['startTime'] = this.params.startTime
      data['endTime'] = this.params.endTime
      data['invoice_check'] = this.params.invoice_check
      data['invoice_type'] = this.params.invoice_type
      data['platform_invoice'] = this.params.platform_invoice
      data['page_no'] = this.params.page_no
      data['page_size'] = this.params.page_size
      data['sellerId'] = this.params.sellerId
      if (this.thisPage) {
        data.is_all = 0
        data['jd_seller'] = this.params.jd_seller
        data['invoice_check'] = this.params.invoice_check
        data['invoice_type'] = this.params.invoice_type
        data.order_ids = this.idsList
        price = this.choosePrice1
      } else if (this.allPage) {
        data.is_all = 1
        data['jd_seller'] = this.params.jd_seller
        // delete data.order_ids;
        data.order_ids = ['0']
        price = this.allPagePrice
      } else {
        if (this.idsList.length > 0) {
          price = this.choosePrice1
          data.is_all = 0
          data['jd_seller'] = this.params.jd_seller
          data['invoice_check'] = this.params.invoice_check
          data['invoice_type'] = this.params.invoice_type
          data.order_ids = this.idsList
        } else {
          this.$message.error('请先选择开票订单')
          return
        }
      }
      API_Invoice.addHistory(data).then(res => {
        // this.activediv = true;// 为了保持和企业端发票管理一致，能够修改发票信息管理的时候返回上一级，此处废弃
        let is_all = 0
        if (this.thisPage) {
          is_all = 0
        } else if (this.allPage) {
          is_all = 1
        } else {
          is_all = 0
        }
        this.$router.push({
          path: '/finance/invoice/requestInvoice',
          query: {
            id: data.order_ids.toString(),
            price,
            is_all,
            params: data.params
          }
        })
      })
    },
    getReceiptStepTwo () {
      let data = null
      data = { step: 2 }
      if (this.thisPage) {
        data.is_all = 0
        data.order_ids = this.idsList
      } else {
        data.is_all = 1
        // delete data.order_ids;
        data.order_ids = ['0']
      }
      API_Invoice.addHistory(data).then(res => {
        this.activediv = false
        this.$message.success('提交成功')
        // window.location.reload();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep th {
  font-size: 16px;
}

::v-deep .el-range-separator {
  width: 8% !important;
}

.stepbox {
  box-sizing: border-box;
  padding: 20px 0;
  display: flex;
  align-items: center;
  min-width: 900px;
  width: 100%;
  height: 90px;
  background: #fff;
  border-radius: 5px 5px 0 0;

  >div {
    display: inline-block;
    width: 380px;
    height: 48px;
    text-align: center;

    p {
      width: 340px;
      height: 48px;
      background: #00a0e9;
      line-height: 48px;
      margin: 0;
      text-align: center;
      color: #ffffff;
      float: left;
    }

    div {
      float: left;
      width: 0;
      height: 0;
      border-left: 14px solid #00a0e9;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }

    .threeths {
      border-left: 14px solid #ffffff;
      border-top: 24px solid #00a0e9;
      border-bottom: 24px solid #00a0e9;
    }
  }

  .activediv {
    p {
      background: #c0c4cc;
    }

    .threeths {
      border-top: 24px solid #c0c4cc;
      border-bottom: 24px solid #c0c4cc;
      border-left: 14px solid #ffffff;
    }

    div {
      float: left;
      border-left: 14px solid #c0c4cc;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }
  }
}

.seletbox {
  padding-left: 30px;
  width: 100%;
  min-width: 900px;
  height: 120px;
  background: #fff;
  padding-top: 30px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;

  p {
    line-height: 25px;
    margin: 0;
  }

  >div {
    display: inline-block;
    margin-top: 20px;
  }

  .hover {
    >span {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }

    span:hover {
      color: #00a0e9;
    }
  }
}

.databox {
  padding-bottom: 30px;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;

  >p {
    padding: 0;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 22px;
    color: #393c41;
  }

  >p:before {
    content: '';
    display: block;
    width: 6px;
    height: 22px;
    background: #1a43a9;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
  }

  >div {
    background: #ffffff;
    padding: 20px 0;
    border-radius: 5px;
    width: 100%;

    div {
      display: inline-block;
      width: 40%;

      p {
        margin: 0;
        width: 100%;
        padding-left: 5%;
        text-align: left;
        line-height: 24px;
        border-right: 1px solid #dcdfe6;
      }
    }

    .goright {
      display: inline-block;
      padding-left: 4%;
      width: 19%;
    }
  }

  .yikai {
    color: #e3405b;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }

  .daikai {
    color: #5dbe2d;
    font-size: 30px;
    padding-top: 10px;
    font-weight: bold;
  }

  .noueb {
    border: none;
    margin-left: -7%;
  }
}
</style>
<style scoped>
.detal {
  line-height: 35px;
}

.detal {
  line-height: 35px;
}

.span {
  display: inline-block;
  width: 150px;
  text-align: right;
}

.detal h3 {
  padding: 0;
  position: relative;
  padding-left: 18px;
  font-size: 16px;
  line-height: 22px;
  color: #393c41;
  font-weight: normal;
}

.detal h3:before {
  content: '';
  display: block;
  width: 6px;
  height: 22px;
  background: #1a43a9;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}

.toolbar-search {
  width: 100% !important;
}
</style>
